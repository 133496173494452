<template>
  <div @keydown.esc.exact.prevent="destroyInstance()">
    <FullCalendar
      ref="calendar"
      data-cy="calendar-view"
      :style="colorOverrides"
      class="full-calendar-wrapper"
      :options="calendarOptions"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapState, mapGetters } from 'vuex';
import FullCalendar from '@fullcalendar/vue3';
import dayGridViewPlugin from '@fullcalendar/daygrid';
import timeGridViewPlugin from '@fullcalendar/timegrid';
import listViewPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import EventPopUp from './EventPopUp';
import EventClockIcon from '../../icons/EventClockIcon.vue';
import ChevronBackIcon from '../../icons/ChevronBackIcon.vue';
import ChevronNextIcon from '../../icons/ChevronNextIcon.vue';
import { hexToRgb, blendAlpha } from '../../../../../helpers/colors';
import { TOOLTIP_POSITIONING, VIEW_TYPES, getSpecificListOrCalView } from '../../helpers/events.helper';
import { h, render } from 'vue';

const MOBILE_WIDTH = 600;

export default {
  name: 'FullCalendarWrapper',
  components: {
    FullCalendar,
  },
  props: {
    events: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['change-view-type'],
  data() {
    return {
      viewType: VIEW_TYPES.MONTH,
    };
  },
  computed: {
    ...mapState('events', ['lastActiveEndDate', 'queryParams', 'selectedYearUpdated', 'calendarViewType']),
    ...mapState(['globals', 'customSections']),
    ...mapGetters({ headingColor: 'getThemeHeadingColor' }),
    ...mapGetters('events', {
      month: 'getCurrentMonth',
      year: 'getCurrentYear',
      day: 'getCurrentDay',
      date: 'getCurrentDate',
    }),
    accessibleWeekButtonLabel() {
      const startDate = dayjs(this.queryParams.start_date).format('MMMM-D-YYYY');
      const endDate = dayjs(this.queryParams.end_date).subtract(1, 'day').format('MMMM-D-YYYY');
      return `${startDate} - ${endDate}`;
    },
    accessibleMonthButtonLabel() {
      const currentMonth = dayjs(this.queryParams.start_date).format('MMMM');
      return currentMonth;
    },
    colorOverrides() {
      const color = this.headingColor;
      if (!color) {
        return {};
      }

      const targetRGB = { ...hexToRgb(color), a: 0.1 };
      const backgroundRGB = blendAlpha({ ...hexToRgb(color), a: 0.08 });

      return {
        '--button-outline': color,
        '--button-primary-bg': color,
        '--button-primary-bg-hover': color,
        '--table-border': `${color}1A`,
        '--event-bg': `${blendAlpha(targetRGB, true, backgroundRGB)}`,
        '--today-highlight-color': `${color}14`,
        '--month-week-border-color': `${color}4D`,
        '--month-week-bg-active': `${color}1A`,
        '--border-events': `1px solid ${color}`,
        '--month-week-width': this.viewType === VIEW_TYPES.WEEK ? '315px' : '215px',
      };
    },
    calendarOptions() {
      return {
        locale: this.$intl.locale,
        plugins: [dayGridViewPlugin, timeGridViewPlugin, listViewPlugin, interactionPlugin],
        initialView: this.calendarViewType || 'dayGridMonth',
        headerToolbar: {
          left: 'customLeft,title,customRight',
          center: '',
          right: 'customToday dayGridMonth,timeGridWeek',
        },
        buttonText: {
          today: this.$t('events.today'),
        },
        events: this.events,
        eventOrder: 'start_at',
        eventClick: this.handleEventClick,
        viewDidMount: this.handleViewDidMount,
        datesSet: this.handleChangeDate,
        views: {
          dayGridMonth: {
            buttonText: this.$t('events.month'),
          },
          timeGridWeek: {
            buttonText: this.$t('events.week'),
            allDaySlot: true,
            allDayText: this.$t('events.allDayUppercase'),
            slotMinWidth: '500px',
          },
        },
        customButtons: {
          customToday: {
            text: 'Today',
            click: () => {
              this.todayButtonAction();
            },
          },
          customLeft: {
            text:
              this.viewType === 'timeGridWeek'
                ? this.$t('events.previousWeek', { accessibleWeek: this.accessibleWeekButtonLabel })
                : this.$t('events.previousMonth', { accessibleMonth: this.accessibleMonthButtonLabel }),
            icon: 'chevron-left',
            click: () => {
              this.calendar.prev();
            },
          },
          customRight: {
            text:
              this.viewType === 'timeGridWeek'
                ? this.$t('events.nextWeek', { accessibleWeek: this.accessibleWeekButtonLabel })
                : this.$t('events.nextMonth', { accessibleMonth: this.accessibleMonthButtonLabel }),
            icon: 'chevron-right',
            click: () => {
              this.calendar.next();
            },
          },
        },
        height: 'auto',
        initialDate: this.date,
        eventDidMount: (info) => {
          const { el, event, view } = info;
          el.href = 'javascript:void(0);';
          el.onfocus = this.destroyInstance;
          if (view.type === VIEW_TYPES.WEEK) {
            const { formatted_start, formatted_end, all_day } = event.extendedProps;
            const start = dayjs(formatted_start);
            const end = dayjs(formatted_end);
            const isMultiDay = start.date() !== end.date();
            const eventTitleNode = el.querySelector('.fc-event-title');
            if (isMultiDay && !all_day) {
              const clockIconInstance = this.createSVGClockInstance();
              render(clockIconInstance, eventTitleNode);
            }

            if (event.allDay) {
              const viewStartDate = dayjs(view.currentStart);
              const viewEndDate = dayjs(view.currentEnd);

              if (start.isBefore(viewStartDate)) {
                const chevronIconInstance = this.createSVGChevronInstance(false);
                render(chevronIconInstance, eventTitleNode);
              }

              if (end.isAfter(viewEndDate)) {
                const chevronIconInstance = this.createSVGChevronInstance(true);
                render(chevronIconInstance, eventTitleNode);
              }
            }
          }
        },
        allDayContent: '',
      };
    },
  },
  mounted() {
    this.calendar = this.$refs.calendar.getApi();
    document.querySelectorAll('.fc-icon').forEach((elem) => {
      elem.tabIndex = -1;
    });
  },
  methods: {
    getFollowingMonth(currStartYear, startMonth) {
      return {
        startDate: new Date(currStartYear, startMonth + 1, 1).toISOString().split('T')[0],
        endDate: new Date(currStartYear, startMonth + 2, 0).toISOString().split('T')[0],
      };
    },
    todayButtonAction() {
      const todaysDate = new Date();
      const payload = {
        start: todaysDate,
        end: todaysDate,
        view: {
          currentStart: todaysDate,
          currentEnd: todaysDate,
        },
      };
      this.handleChangeDate(payload, true);
    },
    handleChangeDate(payload, todayAction) {
      this.$store.commit('events/setCalViewType', this.viewType);
      if (this.dateChanged(payload) || todayAction) {
        this.$store.commit('events/setLastActiveEndDate', payload.view.activeEnd);
        const day = payload.view.currentStart.getDate();
        const startMonth = payload.view.currentStart.getMonth();
        const endMonth = payload.view.currentStart.getMonth() + 1;
        let currStartYear = payload.view.currentStart.getFullYear();
        let startDate = new Date(currStartYear, startMonth, 1).toISOString().split('T')[0];
        let endDate = new Date(currStartYear, endMonth, 0).toISOString().split('T')[0];
        if (this.viewType === 'timeGridWeek') {
          if (this.selectedYearUpdated) {
            this.$store.commit('events/setSelectedYearUpdated', false);
            currStartYear = this.year;
          }
          startDate = new Date(currStartYear, payload.start.getMonth(), payload.start.getDate())
            .toISOString()
            .split('T')[0];
          endDate = new Date(payload.end.getFullYear(), payload.end.getMonth(), payload.end.getDate())
            .toISOString()
            .split('T')[0];
        }
        this.$store.commit('events/setCurrentDate', {
          year: currStartYear,
          month: startMonth,
          day,
        });
        return navigateTo({
          query: {
            ...this.$route.query,
            ...this.queryParams,
            start_date: startDate,
            end_date: endDate,
            date: undefined,
            id: undefined,
            view: getSpecificListOrCalView(this.$store.state.events),
          },
        });
      }
    },
    handleEventClick($event) {
      const { jsEvent, event, el } = $event;
      jsEvent.preventDefault();
      jsEvent.stopPropagation();

      if (this.childInstance && event.extendedProps.id === this.childInstance.props.event.id) {
        this.destroyInstance();
        return;
      }

      if (this.childInstance) {
        this.destroyInstance();
      }

      const eventData = event.extendedProps;

      let windowWidth = null;
      if (import.meta.client) {
        windowWidth = window.innerWidth;
      }

      if (windowWidth < MOBILE_WIDTH) {
        this.clickedEventData = eventData;
        return;
      }
      this.createNewInstance(eventData, el);
    },

    createNewInstance(event, el) {
      const rect = this.$el.getBoundingClientRect();
      const parentBoundingBox = {
        element: this.$el,
        top: rect.top + window.scrollY,
        bottom: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
        right: rect.right + window.scrollX,
        width: rect.width,
        height: rect.height,
        x: rect.x + window.scrollX,
        y: rect.y + window.scrollY,
      };
      const elementBounding = el.getBoundingClientRect();
      const originalRefNode = this.viewType === VIEW_TYPES.WEEK ? el.parentNode.parentNode : el.parentNode;
      const instance = h(EventPopUp, {
        event: {
          ...event,
          eventTags: this.eventTags(event),
        },
        getGlobals: this.globals,
        tooltipPosition: TOOLTIP_POSITIONING(el.parentNode, this.$el, this.viewType),
        parentBoundingBox: parentBoundingBox,
        colorOverrides: this.colorOverrides,
        eventYPos: elementBounding.top + elementBounding.height / 2,
        viewType: this.calendarViewType,
        onClickedAway: () => this.destroyInstance(),
        onCloseEsc: () => el.focus(),
        onViewEvent: ($event) => this.viewEvent($event),
      });
      instance.appContext = this._.appContext;
      this.childInstance = instance;
      this.originalRef = originalRefNode;
      render(instance, originalRefNode);
    },
    viewEvent(id) {
      this.$store.commit('events/changeEventView', 'LIST_VIEW');
      return navigateTo({
        query: {
          ...this.$route.query,
          view: undefined,
          id,
        },
      });
    },
    destroyInstance() {
      if (this.originalRef) {
        render(null, this.originalRef);
        this.childInstance = null;
        this.originalRef = null;
      }
    },
    eventTags(event) {
      const tags = [];
      const section = this.customSections.find((sec) => sec.id === event.custom_section_id);
      if (section) tags.push(section.name);
      tags.push(...event.filter_name);
      return tags;
    },
    handleViewDidMount({ view }) {
      if (this.viewType === view.type) return;

      this.viewType = view.type;
      this.$store.commit('events/setCalViewType', this.viewType);

      if (this.calendar) {
        this.calendar.setOption('height', view.type === 'dayGridMonth' ? null : 'auto');
      }
      this.$emit('change-view-type', view.type);
      if (view.type === 'dayGridMonth') {
        const day = view.currentStart.getDate();
        const startMonth = view.currentStart.getMonth();
        let endMonth = view.activeEnd.getMonth();
        const currStartYear = view.currentStart.getFullYear();

        if (this.lastActiveEndDate) {
          endMonth = this.lastActiveEndDate.getMonth();
        }

        const rangeDates =
          endMonth > startMonth
            ? this.getFollowingMonth(currStartYear, startMonth)
            : {
                startDate: view.currentStart.toISOString().split('T')[0],
                endDate: view.currentEnd.toISOString().split('T')[0],
              };

        this.$store.commit('events/setCurrentDate', {
          year: currStartYear,
          month: startMonth,
          day,
        });
        return navigateTo({
          query: {
            ...this.$route.query,
            ...this.queryParams,
            start_date: rangeDates.startDate,
            end_date: rangeDates.endDate,
            date: undefined,
            id: undefined,
            view: getSpecificListOrCalView(this.$store.state.events),
          },
        });
      }
    },
    dateChanged(payload) {
      if (this.viewType === 'timeGridWeek') {
        return this.date.toISOString() !== payload.start.toISOString();
      }
      return this.date.getMonth() !== payload.view.currentStart.getMonth();
    },
    createSVGClockInstance() {
      const instance = h(EventClockIcon);
      instance.appContext = this._.appContext;
      return instance;
    },
    createSVGChevronInstance(useNextIcon = false) {
      const instance = h(useNextIcon ? ChevronNextIcon : ChevronBackIcon);
      instance.appContext = this._.appContext;
      return instance;
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.fc) {
  .fc-scrollgrid-section-sticky > * {
    z-index: auto;
  }
  .fc-timegrid-event-harness {
    .fc-timegrid-event {
      min-height: 24px;
    }
  }
  .fc-event {
    .fc-event-title-container {
      flex-grow: 0;
      flex-shrink: 0;
    }
    .fc-event-main {
      background-color: var(--event-bg);

      .fc-event-main-frame {
        flex-direction: column-reverse;
        justify-content: flex-end;
        overflow: hidden;
      }
    }
  }
  .fc-scroller-harness,
  .fc-scroller-liquid-absolute,
  .fc-scroller {
    overflow: visible !important;
  }
  .fc-day:has(.cal-event-popup) {
    z-index: 99;
    position: relative;
  }
}

@mixin cal-button-override {
  background: transparent;
  border: none;
}

@mixin cal-font-override {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;

  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
}

.view-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;

  .view-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 24px;

    .view-header-left,
    .view-header-right {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }

  .full-calendar-wrapper {
    flex: 1;
  }
}

.full-calendar-wrapper {
  margin-top: 32px;

  & :deep() {
    --fc-event-text-color: #333;
    --fc-event-border-color: var(--event-bg);
    --fc-event-bg-color: var(--event-bg);

    --fc-today-bg-color: var(--today-highlight-color);

    --fc-border-color: var(--table-border);
    --fc-button-active-border-color: var(--month-week-border-color);

    .fc-dayGridMonth-view {
      list-style: none;
    }
    & .fc-view-harness {
      min-height: 300px;
    }

    & .fc-timegrid-axis-cushion {
      max-width: none;
      min-width: 100px;
      text-align: center;
    }

    & .fc-daygrid-day-top {
      flex-direction: initial;
      padding-left: 8px;
      font-weight: 500;
    }

    & .fc-col-header-cell-cushion {
      font-weight: 400;
    }

    & .fc-daygrid {
      & .fc-col-header-cell-cushion {
        float: right;
      }
    }

    & .fc-timegrid-slot-label-frame {
      text-align: center;
      text-transform: uppercase;
    }

    & .fc-header-toolbar {
      min-height: 90px;
      padding: 24px;
      border: 1px solid rgba(0, 73, 144, 0.1);
      border-radius: 3px;
      margin: 0;

      @media (max-width: 424px) {
        flex-direction: column;
      }
    }

    & .fc-toolbar-chunk {
      &:first-of-type {
        & div {
          flex: 1;
          display: flex;
          align-items: center;
        }

        & > * {
          & > .fc-toolbar-title {
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 23px;
            width: var(--month-week-width);
            text-align: center;
            color: #333333;
          }

          & > .fc-button {
            @include cal-button-override;
            color: #00376c; /* TODO: Should be Dynamic*/
          }
        }
      }
      &:last-of-type {
        display: flex;
        & > .fc-button {
          @include cal-button-override;
          @include cal-font-override;
          border: 1px solid rgba(0, 73, 144, 0.3);
          border-radius: 4px;
          color: #333333;
          padding: 12px;
          border: 1px solid var(--month-week-border-color);
        }

        & > .fc-button-group {
          border: 1px solid rgba(0, 73, 144, 0.3);
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px;
          margin-left: 8px;
          border: 1px solid var(--month-week-border-color);
        }

        & > * {
          & > .fc-button {
            @include cal-button-override;
            @include cal-font-override;
            color: #333333;

            border-radius: 4px;
            height: 20px;
            &.fc-button-active {
              background: var(--month-week-bg-active);
            }
          }
        }
      }
    }
    & .fc-button.fc-button-primary {
      &.fc-next-button,
      &.fc-prev-button {
        padding: 0;
        & .fc-icon {
          &:focus {
            outline: none;
            box-shadow: none;
          }
        }
        &:focus {
          outline: none;
          box-shadow: none;
          .fc-icon {
            box-shadow: 0 0 0 0.2rem #4c5b6a80;
          }
        }
      }
      &.fc-dayGridMonth-button,
      &.fc-timeGridWeek-button {
        &:focus {
          box-shadow: none;
        }
        &:focus-visible {
          box-shadow: 0 0 0 0.2rem #4c5b6a80;
        }
      }
    }
    &
      > .fc-view-harness
      > div
      > table
      > tbody
      > tr
      > td
      > div
      > div
      > div
      > table
      > tbody
      > tr
      > td.fc-daygrid-day
      > div
      > div.fc-daygrid-day-events
      > div {
      cursor: pointer;

      & > a > div.fc-event-main > div.fc-event-main-frame > div.fc-event-title-container > div.fc-event-title {
        &::marker {
          margin-right: 8px;
        }

        display: list-item;
        list-style-position: inside;
        margin-left: 8px;
      }
    }
  }
}
</style>
